.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    font-family: "Helvetica Neue", sans-serif;

  }
  
  .popup {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    text-align: center;
  }
  
  .heading {
    font-size: 24px;
    margin-bottom: 16px;
  }
  
  .description {
    margin-bottom: 16px;
  }
  
  .disclaimer {
    font-size: 14px;
    color: #888;
    margin-bottom: 16px;
  }
  
  .checkboxContainer {
    margin-bottom: 16px;
  }
  
  .checkboxLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
  
  .checkbox {
    margin-right: 8px;
  }
  
  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .acceptButton {
    background-color: #A952B8;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-right: 16px;
  }
  
  .learnMoreLink {
    color: #A952B8;
    text-decoration: none;
    font-size: 16px;
  } 
  
  .disabledButton {
    cursor: not-allowed;
    opacity: 0.6;
  }
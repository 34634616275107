.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #A952B8;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-bottom: 16px;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .text {
    color: #fff;
    font-size: 18px;
    text-align: center;
    font-family: "Helvetica Neue", sans-serif;
  }
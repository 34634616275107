/* AdminDashboard.css */
.admin-page {
    padding: 2rem;
    min-height: calc(100vh - 100px);
  }
  
  .admin-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .admin-grid {
    display: flex;
    /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
    gap: 2rem;
    margin-top: 2rem;
    width: 100%;
  }
  
  .admin-card {
    background: #ffffff;
    border-radius: 16px;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .admin-card-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #1e293b;
    margin-bottom: 1.5rem;
  }
  
  .admin-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .admin-input-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .admin-input-label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #666;
  }
  
  .admin-input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    font-size: 0.875rem;
    transition: all 0.2s;
  }
  
  .admin-input:focus {
    outline: none;
    border-color: #7f56d9;
    box-shadow: 0 0 0 2px rgba(127, 86, 217, 0.1);
  }
  
  .admin-select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    font-size: 0.875rem;
    background-color: white;
    cursor: pointer;
  }
  
  .admin-checkbox-group {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .admin-checkbox-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    font-size: 0.875rem;
    color: #666;
  }
  
  .admin-button {
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    font-weight: 500;
    transition: all 0.2s;
    cursor: pointer;
  }
  
  .admin-button-primary {
    background-color: #7f56d9;
    color: white;
    border: none;
  }
  
  .admin-button-primary:hover {
    background-color: #6941c6;
  }
  
  .admin-button-ghost {
    background-color: transparent;
    border: 1px solid #e2e8f0;
    color: #666;
  }
  
  .admin-button-ghost:hover {
    background-color: #f8fafc;
  }
  
  .admin-error {
    color: #ef4444;
    font-size: 0.875rem;
    margin-top: 0.5rem;
  }
  
  .boxes-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .box-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #f8fafc;
    border-radius: 8px;
    transition: all 0.2s;
  }
  
  .box-item:hover {
    background-color: #f1f5f9;
  }
  
  .box-info {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .box-name {
    font-weight: 500;
    color: #1e293b;
  }
  
  .box-details {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .box-category {
    font-size: 0.875rem;
    color: #64748b;
    padding: 0.25rem 0.5rem;
    background-color: #f1f5f9;
    border-radius: 4px;
  }
  
  .box-pinned {
    font-size: 0.75rem;
    color: #059669;
    background-color: #dcfce7;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
  }
  
  .box-address {
    font-size: 0.75rem;
    color: #94a3b8;
    word-break: break-all;
  }
  
  .delete-button {
    padding: 0.5rem 1rem;
    background-color: #fee2e2;
    color: #ef4444;
    border: none;
    border-radius: 6px;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .delete-button:hover {
    background-color: #fecaca;
  }
  
  .login-container {
    max-width: 400px;
    margin: 4rem auto;
    padding: 2rem;
    background: white;
    border-radius: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .login-title {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    color: #1e293b;
    margin-bottom: 1.5rem;
  }
  
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    font-size: 1.125rem;
    color: #64748b;
  }
  
  .no-boxes {
    text-align: center;
    padding: 2rem;
    color: #64748b;
    font-size: 0.875rem;
    background-color: #f8fafc;
    border-radius: 8px;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .admin-page {
      padding: 1rem;
    }
  
    .admin-header {
      flex-direction: column;
      gap: 1rem;
      text-align: center;
    }
  
    .admin-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  
    .box-item {
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start;
    }
  
    .box-info {
      width: 100%;
    }
  
    .delete-button {
      width: 100%;
    }
  
    .login-container {
      margin: 2rem 1rem;
      padding: 1.5rem;
    }
  }
  
  /* Animation Classes */
  .fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }
  
  .fade-exit {
    opacity: 1;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity 200ms ease-in;
  }
  
  /* Custom Scrollbar */
  .boxes-list {
    max-height: 600px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #cbd5e1 #f1f5f9;
  }
  
  .boxes-list::-webkit-scrollbar {
    width: 8px;
  }
  
  .boxes-list::-webkit-scrollbar-track {
    background: #f1f5f9;
    border-radius: 4px;
  }
  
  .boxes-list::-webkit-scrollbar-thumb {
    background-color: #cbd5e1;
    border-radius: 4px;
    border: 2px solid #f1f5f9;
  }
  
  /* Toast Customization */
  .Toaster__toast {
    border-radius: 8px !important;
    font-size: 0.875rem !important;
  }
  
  .Toaster__toast--success {
    background-color: #059669 !important;
  }
  
  .Toaster__toast--error {
    background-color: #ef4444 !important;
  }
  
  /* Focus States */
  .admin-input:focus-visible,
  .admin-select:focus-visible,
  .admin-button:focus-visible {
    outline: 2px solid #7f56d9;
    outline-offset: 2px;
  }
  
  /* Hover States */
  .admin-input:hover,
  .admin-select:hover {
    border-color: #cbd5e1;
  }
  
  /* Disabled States */
  .admin-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  /* Required Field Indicator */
  .admin-input-label.required::after {
    content: "*";
    color: #ef4444;
    margin-left: 4px;
  }
.c-complaint_body {
overflow: hidden;
display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 2;
}


.navbar-btm {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.navbar-btm.active {
  max-height: 500px; /* Adjust this value based on the content size */
}


input {
  border: none;
  outline: none; /* Removes focus outline (if you want this too) */
  box-shadow: none; /* Removes any potential shadow */
}

.navbar-menu{
  cursor: pointer;
}

.c-related_pill.selected {
  background-color: #0052ff;
  color: white;
}


.page-header.dashboard{
  margin-top: 20px;
}


.navbar .coming-soon {
  color: #f904f9; /* Red or any color you prefer */
  font-size: 0.8rem; /* Slightly smaller than the main text */
  margin-left: 5px; /* Small spacing between the label and 'Analytics' text */
  font-style: italic; /* Italic to differentiate */
}



.swiper{
  width: 248px;
}

.swiper .swiper-slide{
background-color: white;
border-radius: 22px;
border: 1px solid #00000012;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 8px;
  /* padding: 20px; */
  max-width: 600px;
  /* width: 100%; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  background: transparent;
  border: none;
  }


@media (max-width: 767px) {
  .nft-details { 
    width  : 100%
  }
}
@media (max-width: 479px) {
  .navbar {
    width  : 85vw
  }
}


@media only screen and (max-width: 479px) {
  .analytics {
    display: none;
  }
}


.terms-modal{
  margin-top: 20px;
}

.page-header.cc-complain{
  margin-top: 40px;
}


.sentence-case {
  text-transform: capitalize;
}


.hidden{
  display : none
}